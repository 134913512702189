<template>
  <div>
    <gl-title title="会员礼品"></gl-title>
    <div style="margin: 18px;">
      <el-button type="primary" size="mini" @click="mClickAction('mUpdateVisible')"> 新增 </el-button>
    </div>
    <gl-card>
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="giftSetId" label="礼品ID" width="160"></el-table-column>
        <el-table-column prop="giftName" label="礼品名称"></el-table-column>
        <el-table-column prop="giftIntroduce" label="礼品介绍" show-overflow-tooltip></el-table-column>
        <el-table-column prop="vipName" label="绑定的VIP套餐"></el-table-column>
        <el-table-column label="操作" width="210px" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="text" @click="mClickAction('mUpdateVisible', row.giftSetId)"> 编辑 </el-button>
            <el-button type="text" @click="mDelete(row.giftSetId)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange"
      ></gl-pagination>
    </gl-card>
    <Update :visible.sync="mUpdateVisible" :itemId="mItemId" @reload="mReload"/>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import { giftList, deleteGift } from '@/api/vip'
import Update from './update.vue'
export default {
  mixins: [ListMixin],
  components: {Update},
  mounted(){
    this.mGetListFun = giftList
    this.mDeleteFun = deleteGift
    this.mGetList()
  }
}
</script>

<style>

</style>