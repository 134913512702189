<template>
  <el-dialog 
    :title="itemId ? '修改' : '新增'"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="680px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="150px" size="mini" v-loading="loading">
        <el-form-item prop="giftName" label="礼品名称" verify>
          <el-input v-model="formData.giftName" placeholder="请输入礼品名称" :maxlength="20"></el-input>
        </el-form-item>
        <el-form-item prop="giftPicture" label="礼品图片" verify :watch="formData.vipLogo">
          <gl-upload-images 
            :limit="1" 
            :type="2"
            v-model="formData.giftPicture" 
            ref="uploadImages" 
            :oldList="oldList" 
            :autoUpload="true">
          </gl-upload-images>
        </el-form-item>
        <el-form-item prop="vipId" label="关联的VIPID" verify>
          <gl-option v-model="formData.vipId" :list="vipList"></gl-option>
        </el-form-item>
        <el-form-item prop="giftIntroduce" label="礼品介绍" verify>
          <!-- <el-input type="textarea" v-model="formData.giftIntroduce" :rows="5" placeholder="请输入礼品介绍" :maxlength="2000"/> -->
          <gl-editor v-model="formData.giftIntroduce" placeholder="请输入礼品介绍"></gl-editor>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import { getGift, addGift, updateGift, vipList } from '@/api/vip'
export default {
  props: {
    itemId: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      formData: {},
      oldList: [],
      loading: false,
      vipList: [],
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.fetchGift()
      }
    },
  },
  mounted(){
    this.getVipList()
  },
  methods: {
    async getVipList(){
      const res = await vipList()
      this.vipList = res.data.rows.map(item => {
        return{
          name: item.vipName,
          value: item.vipId,
        }
      })
    },
    async fetchGift(){
      this.loading = true
      try{
        const res = await getGift(this.itemId)
        this.loading = false
        this.formData = res.data
        this.oldList = [res.data.giftPicture]
      }catch(e){
        this.loading = false
      }
    },
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true

        const submitFun = this.itemId ? updateGift : addGift
        const formData = {
          ...this.formData,
          giftSetId: this.itemId,
          vipName: this.vipList.find(item => item.value == this.formData.vipId).name
        }
        const res = await submitFun(formData)
        this.loading = false
        this.$message.success('操作成功')
        this.handleClose()
        this.$emit('reload')
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
      // this.formData = {}
      // setTimeout(() => {
      //   this.$refs.form.resetFields()
      // }, 100)
    },
  },
}
</script>

<style>

</style>