import request from '@/plugins/request'

export function vipList(data = {}){
  return request({
    url: '/api/user/set/vipSetList',
    method: 'POST',
    data: data,
  })
}

export function fetchVip(vipId){
  return request({
    url: '/api/user/set/getVipSet',
    method: 'POST',
    params: {vipId},
  })
}

export function addVip(data = {}){
  return request({
    url: '/api/user/set/addVipSet',
    method: 'POST',
    data,
  })
}

export function updateVip(data = {}){
  return request({
    url: '/api/user/set/updateVipSet',
    method: 'POST',
    data,
  })
}

export function deleteVip(vipId){
  return request({
    url: '/api/user/set/deleteVipSet',
    method: 'POST',
    params: {vipId},
  })
}







// 会员礼物模块
export function giftList(data){
  return request({
    url: '/api/user/gift/GiftSetList',
    method: 'POST',
    data,
  })
}

export function getGift(itemId){
  return request({
    url: '/api/user/gift/getGiftSetId',
    method: 'GET',
    params: {giftSetId: itemId},
  })
}

export function addGift(data){
  return request({
    url: '/api/user/gift/addGiftSet',
    method: 'POST',
    data,
  })
}

export function updateGift(data){
  return request({
    url: '/api/user/gift/updateGiftSet',
    method: 'POST',
    data,
  })
}

export function deleteGift(id){
  return request({
    url: '/api/user/gift/deleteGiftSet',
    method: 'GET',
    params: {giftSetId: id},
  })
}




// VIP购买记录
export function vipBuyRecordList(data = {}){
  return request({
    url: '/api/user/set/vipBuyRecordList',
    method: 'POST',
    data: data,
  })
}